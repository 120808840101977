#input-check-winner:checked {
    background-color: $sucess-color;
    border-color: $sucess-color;
}

#input-check-winner {
    border: 2px solid;
    width: 30px;
    height: 30px;
}

.img-logo_next_event {
    width: 80px;
}