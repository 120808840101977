.card-form_register_user {
    margin: 20px;
    max-width: auto;
}

.container {
    & .row {
        & .card-form_register_user {
            & .btn-create_account {
                background-color: #172b4d;
                font-size: 20px;
                width: 100%;
                border: #172b4d;
                transition-property: transform, background-color;
                transition-duration: 0.2s;
            }

            .btn-create_account:hover {
                transform: translatey(-2px);
            }

            .btn-create_account:active {
                background-color: black;
            }
        }
    }
}