.th-container_rank {
    width: 20px;
}

.container-img_rank {
    width: 20px;

    & .img-rank {
        width: 12px;
    }
}