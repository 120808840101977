.label-check-fixture-bets-options {
	display: flex;
	align-items: center;
	height: 48px;
	font-size: 12px;
}

.img-logo_team_fixture_bets {
	width: 60px;
	height: auto;
	object-fit: cover;
}

.label-input-fixture-bets-score {
	height: 42px;
	display: flex;
	align-items: center;
	font-size: 14px;
}

.container-date-matchs {
	font-size: 14px;
}

.container-names-equip-matchs {
	font-size: 14px;
}

.container-text-select-winner-team {
	font-size: 14px;
}

.title-name-group-fixture-bets {
	font-size: 14px;
	// position: absolute;
}

.container-input-number-fixture-bets {
	.input-number-fixture-bets {
		width: 60px;
	}
}

.card-container-info-selftFixtureBets {
	width: 94%;
	max-width: 500px;
}

.row-grid-checks-input {
	display: grid !important;
	grid-template-columns: 1fr 1fr;
	.grid-colum-input-check-2 {
		order: 3;
		grid-column: 1/3;
	}
	.container-form-check {
		.form-check {
			.input-check-fixture-bets {
				cursor: pointer;
				float: none;
				margin-left: 0;
				margin-bottom: 15px;
				width: 22px;
				height: 22px;
				border-color: black;
				margin: auto 0;
			}

			.draw-score-input-check {
				height: auto;
			}

			.input-check-fixture-bets:checked {
				background-color: #172b4de5;
				border-color: #172b4de5;
			}

			.input-check-fixture-bets:active {
				background-color: #172b4d;
			}
		}
	}
}

@media (min-width: 500px) {
	.row-grid-checks-input {
		display: flex !important;
		justify-content: center;
		align-items: center;
		.grid-colum-input-check-2 {
			order: unset;
			grid-column: unset;
		}
		.container-form-check {
			.form-check {
				.draw-score-input-check {
					height: 48px;
				}
			}
		}
	}
}
