.container {
    .form-container {
        .button-form-create-new-group {
            background-color: #172b4d;
            border: #172b4d;
            transition-property: transform, background-color;
            transition-duration: 0.2s;
        }

        .button-form-create-new-group:hover {
            transform: translatey(-3px);
        }

        .button-form-create-new-group:active {
            background-color: black;
        }

    }
}