$default-color: #172b4d;
$primary-color: #5e72e4;
// $secondary-color: #f4f5f7;

// $info-color: #11cdef;
$sucess-color: #2dce89;
// $danger-color: #f5365c;
// $warning-color: #fb6340;

$background-color: #f8f9fe;
// $contrast-color: #f7fafc;

// $color: #7a7a96;
// $very-ligth-color: #b2bdc9;


body {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 0.9em !important;
}

.Toastify__toast-theme--light {
    color: $background-color !important;
    background-color: #172b4d !important;
}

.Toastify__close-button--light {
    color: $background-color !important;
}