.card-modal {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  position: absolute !important;
  top: 150px;
  right: 14px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


@media (min-width: 992px) {
  .card-modal {
    right: 60px;
  }
}

// Css in the top is for the modal whe you create a group



.modal-login {
  position: fixed;
  z-index: 1;
  // padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(164, 19, 58);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/ opacity */
}

/* Modal Content */
// .container-modal-content_login {
//     background-color: $contrast-color;
// }



.modal-login {
  // & .card-modal {
  //   z-index: 1000;
  //   display: flex;
  //   flex-direction: column;
  //   height: 74%;
  //   width: 90%;
  //   position: absolute !important;
  //   top: 150px;
  //   right: 14px;
  //   box-shadow:
  //     rgba(0, 0, 0, 0.25) 0px 54px 55px,
  //     rgba(0, 0, 0, 0.12) 0px -12px 30px,
  //     rgba(0, 0, 0, 0.12) 0px 4px 6px,
  //     rgba(0, 0, 0, 0.17) 0px 12px 13px,
  //     rgba(0, 0, 0, 0.09) 0px -3px 5px;
  // }

  & .card-container_form_login {
    top: 80px;
    width: 80%;
    left: 36px;

    & .container-modal-content_login {
      height: 454px;

      & .container-buttons_navlinks_login {

        & .link-login_forgot_password,
        .link-login_create_account {
          text-decoration: none;
          color: $primary-color;
        }
      }

      & .container-form_login {
        & .btn-login_form {
          background-color: #172b4d;
          border: #172b4d;
          transition-property: transform, background-color;
          transition-duration: 0.2s;
        }

        .btn-login_form:hover {
          transform: translatey(-2px);
        }

        .btn-login_form:active {
          background-color: black;
        }
      }
    }
  }
}

@media (min-width: 980px) {
  .modal-login {
    // & .card-modal {
    //   right: 60px;
    // }

    & .card-container_form_login {

      top: 90px;
      width: 45%;
      left: 370px;

      & .container-modal-content_login {
        height: 540px;

        & .container-form_login {
          .container-button_login {
            margin: 50px 0 10px 0;
          }
        }
      }
    }


    .title-login {
      margin-bottom: 20px;
    }
  }
}