.carousel {
    & .carousel-inner {
    filter: grayscale(70%);

        & .img-carousel {
            height: 100px;
            width: 100%;
            object-fit: cover;
        }
    }
}

.carousel {
    & .carousel-inner {
        & .overlay {
            font-size: 40px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
        }
    }
}

@media ( min-width: 992px){
    .carousel {
        & .carousel-inner {
            & .overlay {
                padding-bottom: 20px;
                font-size: 62px;
            }
        }
    }
}