.modal-request {
    position: fixed;
    z-index: 1;
    // padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.8);
    /* Black w/ opacity */
}

.modal-request {
    & .card-container_message {
        // top: 80px;
        // width: 80%;
        // left: 36px;
    }
}