.card-modal {
    .card-body {
        .btn-modal-info-create-group {
            background-color: #172b4d;
            border: #172b4d;
            transition-property: transform, background-color;
            transition-duration: 0.2s;
        }

        .btn-modal-info-create-group:hover {
            transform: translatey(-3px);
        }

        .btn-modal-info-create-group:active {
            background-color: black;
        }
    }
}