.navbar {
    background-color: $default-color;
    & .img {
        object-fit:contain;
        width: 30px;
        height: 34px;
        margin: 0 16px;
    }
    & .dropdown-item:active {
        background-color: $default-color;
    }
    & .container-welcome-setting {
        padding-left: 0;
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

}

@media (min-width: 992px) {
    .navbar {
        background-color: $default-color;
        & .img {
            object-fit:contain;
            width: 30px;
            height: 34px;
            margin: 0 16px;
        }
        & .dropdown-item:active {
            background-color: $default-color;
        }
        & .container-welcome-setting {
            display: flex;
            justify-content: end;
            width: 60%;
        }
    
    }
}
