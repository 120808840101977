.btn-login,
.btn-register {
    width: 200px;
}

.img-prueba {
    width: 30px;
    object-fit: contain;
}

.h5-info_home {
    color: $primary-color;
}

@media(max-width: 440px) {
    .btn-login {
        margin-bottom: 8px;
    }
}