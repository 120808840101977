.container {
    & .row {
        & .info-register_home {
            padding: 100px 12px;
        }
    }
}

.container {
    & .row {
        & .btn-register_home {
            margin-bottom: 50px;
        }
    }
}

.title-h5_features {
    color: #5e72e4;
}

.title-home_main_color {
    color: #172b4d;
}

.container-img_icons {
    padding: 50px;
    border-bottom: 1px rgba(0, 0, 0, 0.089) solid;
    border-top: 1px rgba(0, 0, 0, 0.089) solid;
}

.ul-li_home>li {
    margin-bottom: 24px;
}

.img-hom_funtions {
    object-fit: contain;
    padding: 2px;
    height: 80px;
    width: 80px;
}

.container {
    & .row {
        & .btn-register-down {
            background-color: #172b4d;
            font-size: 30px;
            height: 60px;
            width: 300px;
            border: #172b4d;
            transition-property: transform, background-color;
            transition-duration: 0.2s;
        }

        .btn-register-down:hover {
            transform: translatey(-2px);
        }

        .btn-register-down:active {
            background-color: black;
        }

    }
}