.container-notFound {
    height: 100vh;
    margin: 50px auto;
    display: flex;
    flex-direction: column;

    & .conteiner-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    & .container-img {
        display: flex;
        flex-direction: column;
        align-items: center;

        & .img-notFound {
            transform: scale(2);
            width: 320px;
            height: auto;
            object-fit: cover;
        }
    }
}

@media (min-width: 992px) {
    .container-notFound {
        height: 100%;
        margin: 20px;
        display: flex;
        flex-direction: row;

        & .conteiner-text {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & .h1-notFound,
            .p-error {
                font-size: 70px;
            }
        }

        & .container-img {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & .img-notFound {
                transform: none;
                width: 500px;
                height: 500px;
                object-fit: cover;
            }
        }
    }
}