.container-fluid {
	.row {
		.buttons-page-groups {
			background-color: #172b4d;
			border: #172b4d;
			transition-property: transform, background-color;
			transition-duration: 0.2s;
		}

		.buttons-page-groups:hover {
			transform: translatey(-3px);
		}

		.buttons-page-groups:active {
			background-color: black;
		}
	}
}
